import { useEffect, useState } from 'react'
import type { FC } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import {
  Box,
  Card,
  CardContent,
  Container,
  // Divider,
  // Link,
  Typography,
  // Alert,
  Button,
  FormHelperText,
  TextField,
  Popover,
  Divider,
  IconButton,
} from '@mui/material'
// import AuthBanner from '../../components/authentication/AuthBanner';
// import {
//   LoginAmplify,
//   LoginAuth0,
//   LoginFirebase,
//   LoginJWT,
// } from "../components/authentication/login";
import Logo from '../components/Logo'
import useAuth from '../hooks/useAuth'
import useMounted from '../hooks/useMounted'
import gtm from '../lib/gtm'
import * as Yup from 'yup'
import { Formik } from 'formik'

const platformIcons = {
  Amplify: '/static/icons/amplify.svg',
  Auth0: '/static/icons/auth0.svg',
  Firebase: '/static/icons/firebase.svg',
  JWT: '/static/icons/jwt.svg',
}

enum POPUP {
  NONE = 'NONE',
  REG = 'REG',
  BALANCE = 'BALANCE',
}

const Login: FC = (props) => {
  const { platform } = useAuth() as any
  const mounted = useMounted()
  const { login } = useAuth() as any

  const [popState, setPopState] = useState<POPUP>(POPUP.NONE)
  const [amt, setAmt] = useState<number>(0)
  const [anchorEl, setAnchorEl] = useState(null)
  const [viewAgent, setViewAgent] = useState(null)
  const [agents, setAgents] = useState([])

  const updateAmt = (value) => {
    if (value === 0) {
      setAmt(0)
    } else {
      setAmt(amt + value * 10000000)
    }
  }

  useEffect(() => {
    gtm.push({ event: 'page_view' })
  }, [])

  return (
    <>
      <Helmet>
        <title>Login | Material Kit Pro</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}
      >
        {/* <AuthBanner /> */}
        <Container maxWidth="sm" sx={{ py: '80px' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mb: 8,
            }}
          >
            {process.env.REACT_APP_API_IS_PRIME === 'Y' ? (
              <img src="/image/PRIME_WHITE.png" alt="My Happy SVG" style={{ width: '200px' }} />
            ) : (
              <img src="/image/pluto.png" alt="My Happy SVG" style={{ width: '200px' }} />
            )}
          </Box>
          <Card>
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                p: 4,
              }}
            >
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'space-between',
                  mb: 3,
                }}
              >
                <div>
                  <Typography color="textPrimary" gutterBottom variant="h4">
                    Log in
                  </Typography>
                  <Typography color="textSecondary" variant="body2">
                    Log in on the internal platform
                  </Typography>
                </div>
                <Box
                  sx={{
                    height: 32,
                    '& > img': {
                      maxHeight: '100%',
                      width: 'auto',
                    },
                  }}
                >
                  <img alt="Auth platform" src={platformIcons[platform]} />
                </Box>
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  mt: 3,
                }}
              >
                <Formik
                  initialValues={{
                    email: '',
                    password: '',
                    submit: null,
                  }}
                  validationSchema={Yup.object().shape({
                    email: Yup.string().min(4).max(20).required('Email is required'),
                    password: Yup.string().max(255).required('Password is required'),
                  })}
                  onSubmit={async (values, { setErrors, setStatus, setSubmitting }): Promise<void> => {
                    try {
                      const doLogin = await login(values.email, values.password)

                      console.log(mounted.current)
                      window.location.href = '/dashboard/setting'

                      if (mounted.current) {
                        setStatus({ success: true })
                        setSubmitting(false)
                      }
                    } catch (err) {
                      console.error(err)
                      if (mounted.current) {
                        setStatus({ success: false })
                        setErrors({ submit: err.message })
                        setSubmitting(false)
                      }
                    }
                  }}
                >
                  {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }): JSX.Element => (
                    <form noValidate onSubmit={handleSubmit} {...props}>
                      <TextField
                        autoFocus
                        error={Boolean(touched.email && errors.email)}
                        fullWidth
                        helperText={touched.email && errors.email}
                        label="Email Address"
                        margin="normal"
                        name="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="email"
                        value={values.email}
                        variant="outlined"
                      />
                      <TextField
                        error={Boolean(touched.password && errors.password)}
                        fullWidth
                        helperText={touched.password && errors.password}
                        label="Password"
                        margin="normal"
                        name="password"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="password"
                        value={values.password}
                        variant="outlined"
                      />
                      {errors.submit && (
                        <Box sx={{ mt: 3 }}>
                          <FormHelperText error>{errors.submit as string}</FormHelperText>
                        </Box>
                      )}
                      <Box sx={{ mt: 2 }}>
                        <Button
                          color="primary"
                          disabled={isSubmitting}
                          fullWidth
                          size="large"
                          type="submit"
                          variant="contained"
                        >
                          Log In
                        </Button>
                      </Box>
                      {/* <Box sx={{ mt: 2 }}>
                        <Alert severity="info">
                          <div>
                            Use <b>demo@devias.io</b> and password{" "}
                            <b>Password123!</b>
                          </div>
                        </Alert>
                      </Box> */}
                    </form>
                  )}
                </Formik>
              </Box>
              {/* <Divider sx={{ my: 3 }} />
              <Link
                color="textSecondary"
                component={RouterLink}
                to="/authentication/register"
                variant="body2"
              >
                Create new account
              </Link>
              <Link
                color="textSecondary"
                component={RouterLink}
                sx={{ mt: 1 }}
                to="/authentication/password-recovery"
                variant="body2"
              >
                Forgot password
              </Link> */}
            </CardContent>
          </Card>
        </Container>
      </Box>

      {viewAgent != null && (
        <Popover
          anchorEl={anchorEl}
          anchorOrigin={{
            horizontal: 'center',
            vertical: 'bottom',
          }}
          onClose={() => setPopState(POPUP.NONE)}
          PaperProps={{
            sx: {
              width: 720,
              height: 470,
              overflow: 'hidden',
              top: 0,
            },
          }}
          open={POPUP.BALANCE === popState}
        >
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              px: 2,
              py: 1,
              width: 720,
            }}
          >
            <Typography variant="h6" color="textPrimary">
              {viewAgent.agentId} 에이전트 머니 지급
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <IconButton onClick={() => setPopState(POPUP.NONE)}>{/* <XIcon fontSize="small" /> */}</IconButton>
          </Box>
          <Box
            sx={{
              backgroundColor: 'background.paper',
              minHeight: '100%',
              p: 3,
            }}
          >
            <form onSubmit={(event) => event.preventDefault()}>
              <Box sx={{ mt: 2 }}>
                <Typography color="textPrimary" sx={{ mb: 1 }} variant="subtitle2">
                  충전금액(KRW)
                </Typography>
                <TextField
                  fullWidth
                  label="충전금액(KRW)"
                  name="description"
                  variant="outlined"
                  value={amt}
                  // onFocus={() => setAmt('0')}
                  onChange={(event: any) => setAmt(event.target.value)}
                />
              </Box>
              <Box sx={{ mt: 2 }}>
                <Button
                  color="primary"
                  size="large"
                  sx={{ mr: 1 }}
                  variant="outlined"
                  onClick={() => {
                    updateAmt(0.1)
                  }}
                >
                  1백
                </Button>
                <Button
                  color="primary"
                  size="large"
                  sx={{ mr: 1 }}
                  variant="outlined"
                  onClick={() => {
                    updateAmt(1)
                  }}
                >
                  1천
                </Button>
                <Button
                  color="primary"
                  size="large"
                  sx={{ mr: 1 }}
                  variant="outlined"
                  onClick={() => {
                    updateAmt(5)
                  }}
                >
                  5천
                </Button>
                <Button
                  color="primary"
                  size="large"
                  sx={{ mr: 1 }}
                  variant="outlined"
                  onClick={() => {
                    updateAmt(10)
                  }}
                >
                  1억
                </Button>
                <Button
                  color="primary"
                  size="large"
                  sx={{ mr: 1 }}
                  variant="outlined"
                  onClick={() => {
                    updateAmt(30)
                  }}
                >
                  3억
                </Button>
                <Button
                  color="primary"
                  size="large"
                  sx={{ mr: 1 }}
                  variant="outlined"
                  onClick={() => {
                    updateAmt(50)
                  }}
                >
                  5억
                </Button>
                <Button
                  color="primary"
                  size="large"
                  sx={{ mr: 1 }}
                  variant="outlined"
                  onClick={() => {
                    updateAmt(100)
                  }}
                >
                  10억
                </Button>
                <Button
                  color="primary"
                  size="large"
                  sx={{ mr: 1 }}
                  variant="outlined"
                  onClick={() => {
                    updateAmt(500)
                  }}
                >
                  50억
                </Button>
                <Button
                  color="primary"
                  size="large"
                  sx={{ mr: 1 }}
                  variant="outlined"
                  onClick={() => {
                    updateAmt(1000)
                  }}
                >
                  100억
                </Button>
                <Button
                  color="primary"
                  size="large"
                  sx={{ mr: 1 }}
                  variant="outlined"
                  onClick={() => {
                    updateAmt(0)
                  }}
                >
                  초기화
                </Button>
              </Box>
              <Box sx={{ mt: 2 }}>
                <Typography color="textPrimary" sx={{ mb: 1 }} variant="subtitle2">
                  마이너스(-)로 입력시 보유 금액을 차감합니다.
                </Typography>
                <Typography color="textPrimary" sx={{ mb: 1 }} variant="subtitle2">
                  나의 보유 금액보다 큰 금액을 지급할 수 없습니다.
                </Typography>
                <Typography color="textPrimary" sx={{ mb: 1 }} variant="subtitle2">
                  대상 에이전트의 보유 금액보다 큰 금액을 차감할 수 없습니다.
                </Typography>
              </Box>
              <Divider sx={{ my: 2 }} />
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                <Box sx={{ flexGrow: 1 }} />
                <Button color="primary" variant="text" onClick={() => setPopState(POPUP.NONE)}>
                  취소
                </Button>
                <Button color="primary" sx={{ ml: 1 }} type="submit" variant="contained" onClick={() => {}}>
                  지급
                </Button>
              </Box>
            </form>
          </Box>
        </Popover>
      )}
    </>
  )
}

export default Login
