import { useEffect } from 'react'
import type { FC } from 'react'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Box, Divider, Drawer } from '@mui/material'
import type { Theme } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import ReceiptIcon from '@mui/icons-material/Receipt'
import PaymentIcon from '@mui/icons-material/Payment'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
import LiveHelpIcon from '@mui/icons-material/LiveHelp'
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications'
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle'
import SportsEsportsIcon from '@mui/icons-material/SportsEsports'
import BarChartIcon from '@mui/icons-material/BarChart'
import PieChartIcon from '@mui/icons-material/PieChart'
// import ApiIcon from "@mui/icons-material/Api";
// //import ErrorIcon from "@mui/icons-material/Error";
// import ChartSquareBarIcon from "../../icons/ChartSquareBar";
import UsersIcon from '../../icons/Users'
import PlusIcon from '../../icons/Plus'
import Logo from '../Logo'
import NavSection from '../NavSection'
import Scrollbar from '../Scrollbar'

interface DashboardSidebarProps {
  onMobileClose: () => void
  openMobile: boolean
}

const sections = [
  {
    title: '일반',
    items: [
      // {
      //   title: "대시보드",
      //   path: "/dashboard",
      //   icon: <ChartSquareBarIcon fontSize="small" />,
      // },
      {
        title: '설정 및 정보',
        path: '/dashboard/setting',
        icon: <SettingsApplicationsIcon fontSize="small" />,
      },
      {
        title: '배팅 리밋',
        path: '/dashboard/limit',
        icon: <SettingsApplicationsIcon fontSize="small" />,
      },
    ],
  },
  // {
  //   title: "게임",
  //   items: [
  //     {
  //       title: "물방개 레이싱",
  //       path: "/game/waterbeetle",
  //       icon: <SupervisedUserCircleIcon fontSize="small" />,
  //     },
  //     {
  //       title: "햄스터 레이싱",
  //       path: "/game/hamster",
  //       icon: <SupervisedUserCircleIcon fontSize="small" />,
  //     },
  //     {
  //       title: "워터휠",
  //       path: "/game/waterwheel",
  //       icon: <SupervisedUserCircleIcon fontSize="small" />,
  //     },
  //   ],
  // },

  {
    title: '에이전트',
    items: [
      {
        title: '에이전트 목록',
        path: '/agent/agentList',
        icon: <SupervisedUserCircleIcon fontSize="small" />,
      },
      // {
      //   title: "에이전트 생성",
      //   path: "/agent/agentReg",
      //   icon: <PlusIcon fontSize="small" />,
      // },
      {
        title: '트랜잭션 내역',
        path: '/agent/transactionList',
        icon: <ReceiptIcon fontSize="small" />,
      },
      {
        title: '지급 내역',
        path: '/agent/paymentList',
        icon: <PaymentIcon fontSize="small" />,
      },
      {
        title: '충전 내역',
        path: '/agent/chargeList',
        icon: <AccountBalanceWalletIcon fontSize="small" />,
      },
      // {
      //   title: "충전 신청",
      //   path: "/agent/chargeRequest",
      //   icon: <PlusIcon fontSize="small" />,
      // },
      {
        title: '문의 내역',
        path: '/agent/inquiryList',
        icon: <LiveHelpIcon fontSize="small" />,
      },
      {
        title: '문의 등록',
        path: '/agent/inquiryReg',
        icon: <PlusIcon fontSize="small" />,
      },
    ],
  },
  {
    title: '유저',
    items: [
      {
        title: '유저 목록',
        path: '/user/userList',
        icon: <UsersIcon fontSize="small" />,
      },
      // {
      //   title: "전체 트랜잭션 내역",
      //   path: "/user/transactionList",
      //   icon: <ReceiptIcon fontSize="small" />,
      // },
      // {
      //   title: "지급 내역",
      //   path: "/user/paymentList",
      //   icon: <PaymentIcon fontSize="small" />,
      // },
      {
        title: '배팅 내역',
        path: '/user/bettingList',
        icon: <SportsEsportsIcon fontSize="small" />,
      },
      // {
      //   title: "New 배팅 내역",
      //   path: "/user/newBettingList",
      //   icon: <SportsEsportsIcon fontSize="small" />,
      // },
    ],
  },
  {
    title: '게임',
    items: [
      {
        title: '게임 관리',
        path: '/game/game-setting',
        icon: <SportsEsportsIcon fontSize="small" />,
      },
    ],
  },
  {
    title: '통계',
    items: [
      {
        title: '에이전트 정산',
        path: '/statistic/agentProfit',
        icon: <BarChartIcon fontSize="small" />,
      },
      {
        title: '유저 정산',
        path: '/statistic/userProfit',
        icon: <BarChartIcon fontSize="small" />,
      },
      // {
      //   title: "카지노별 통계",
      //   path: "/statistic/casino",
      //   icon: <PieChartIcon fontSize="small" />,
      // },
      // {
      //   title: "게임별 통계",
      //   path: "/statistic/game",
      //   icon: <PieChartIcon fontSize="small" />,
      // },
      // {
      //   title: "접속자 통계",
      //   path: "/statistic/connector",
      //   icon: <PieChartIcon fontSize="small" />,
      // },
    ],
  },
  {
    title: '개발자',
    items: [
      {
        title: '트랜스퍼 API',
        path: '/dev/basicApi',
        icon: <PieChartIcon fontSize="small" />,
      },
      {
        title: '콜백 API',
        path: '/dev/callbackApi',
        icon: <PieChartIcon fontSize="small" />,
      },
      {
        title: '상태코드',
        path: '/dev/statusCode',
        icon: <PieChartIcon fontSize="small" />,
      },
      // {
      //   title: "심리스 (테스트)",
      //   path: "/dev/extendApi",
      //   icon: <ApiIcon fontSize="small" />,
      // },
      // {
      //   title: "API 에러 로그",
      //   path: "/dev/errorLog",
      //   icon: <ErrorIcon fontSize="small" />,
      // },
    ],
  },
]

const DashboardSidebar: FC<DashboardSidebarProps> = (props) => {
  const { onMobileClose, openMobile } = props
  const location = useLocation()
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'))

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose()
    }
  }, [location.pathname])

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box
          sx={{
            display: {
              lg: 'none',
              xs: 'flex',
            },
            justifyContent: 'center',
            p: 2,
          }}
        >
          <RouterLink to="/">
            <Logo
              sx={{
                height: 40,
                width: 40,
              }}
            />
          </RouterLink>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          {sections.map((section) => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              sx={{
                '& + &': {
                  mt: 3,
                },
              }}
              {...section}
            />
          ))}
        </Box>
      </Scrollbar>
    </Box>
  )

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'background.paper',
            height: 'calc(100% - 64px) !important',
            top: '64px !Important',
            width: 280,
          },
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    )
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: 'background.paper',
          width: 280,
        },
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  )
}

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
}

export default DashboardSidebar
