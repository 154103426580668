import type { FC } from 'react'
import { useEffect, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import { AppBar, Box, IconButton, Toolbar, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import type { AppBarProps } from '@mui/material'
import MenuIcon from '../../icons/Menu'
import AccountPopover from './AccountPopover'
// import ContactsPopover from "./ContactsPopover";
// import ContentSearch from "./ContentSearch";
// import LanguagePopover from "./LanguagePopover";
import Logo from '../Logo'
import NotificationsPopover from './NotificationsPopover'
import { RPC } from '../../connection/rpc'

import { ConvertToMoeny } from '../utility/help'

const service = new RPC()

interface DashboardNavbarProps extends AppBarProps {
  onSidebarMobileOpen?: () => void
}

const DashboardNavbarRoot = styled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === 'light' && {
    backgroundColor: theme.palette.primary.main,
    boxShadow: 'none',
    color: theme.palette.primary.contrastText,
  }),
  ...(theme.palette.mode === 'dark' && {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: 'none',
  }),
  zIndex: theme.zIndex.drawer + 100,
}))

const DashboardNavbar: FC<DashboardNavbarProps> = (props) => {
  const { onSidebarMobileOpen, ...other } = props

  const [info, setInfo] = useState<any>({})
  // const [totalBalance, setTotalBalance] = useState<number>(0);
  // const [totalUserBalance, setTotalUserBalance] = useState<number>(0);

  useEffect(() => {
    async function fetchAndSetUser() {
      const data: any = await service.getInfo()

      if (data.status === 'success') {
        setInfo(data.info)
      } else if (data.status === 'login' || data.state === 'login') {
        window.location.href = '/'
      }
    }
    fetchAndSetUser()
  }, [])

  return (
    <DashboardNavbarRoot {...other}>
      <Toolbar sx={{ minHeight: 64 }}>
        <IconButton
          color="inherit"
          onClick={onSidebarMobileOpen}
          sx={{
            display: {
              lg: 'none',
            },
          }}
        >
          <MenuIcon fontSize="small" />
        </IconButton>
        <RouterLink to="/">
          <Logo
            sx={{
              display: {
                lg: 'inline',
                xs: 'none',
              },
              height: 40,
              width: 40,
            }}
          />
        </RouterLink>
        <Box
          sx={{
            flexGrow: 1,
            ml: 2,
          }}
        />
        {/* <LanguagePopover /> */}
        <Box sx={{ ml: 1 }}>
          {/* <ContentSearch /> */}
          <Typography gutterBottom variant="overline">
            현재 보유 금액
          </Typography>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexWrap: 'wrap',
            }}
          >
            <Typography sx={{ mr: 1 }} variant="h6">
              {ConvertToMoeny(info?.balance)}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ ml: 1 }}>
          <Typography gutterBottom variant="overline">
            하부 에이전트 현재 총 보유 금액
          </Typography>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexWrap: 'wrap',
            }}
          >
            <Typography sx={{ mr: 1 }} variant="h6">
              {ConvertToMoeny(Number(info?.tAgentBalance) - Number(info?.balance))}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ ml: 1 }}>
          <Typography gutterBottom variant="overline">
            하부 유저 현재 총 보유 금액
          </Typography>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexWrap: 'wrap',
            }}
          >
            <Typography sx={{ mr: 1 }} variant="h6">
              {ConvertToMoeny(info?.tUserBalance)}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ ml: 1 }}>
          <NotificationsPopover />
        </Box>
        <Box sx={{ ml: 2 }}>
          <AccountPopover />
        </Box>
      </Toolbar>
    </DashboardNavbarRoot>
  )
}

DashboardNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func,
}

export default DashboardNavbar
