import numeral from 'numeral'

const GameType: any = {
  water_beetle_racing: '물방개',
  vmsoccer: '가상축구',
  vmdog: '가상 개경주',
}

const GameStause: any = {
  finished: '게임종료',
  Wait: '게임대기',
  betwait: '배팅 대기',
  betstarted: '배팅 시작',
  betend: '배팅 종료',
}

const GameColor: any = {
  finished: 'success',
  Wait: 'warning',
  betwait: 'warning',
  betstarted: 'primary',
  betend: 'primary',
}

export const ConvertGame = (text: any) => {
  if (GameType[text] != null) {
    return GameType[text]
  }
  return `${text}`
}

export const ConvertGameStause = (text: any) => {
  if (GameStause[text] != null) {
    return GameStause[text]
  }
  return `${text}`
}

export const ConvertGameColor = (text: any) => {
  if (GameColor[text] != null) {
    return GameColor[text]
  }
  return `error`
}

export const ConvertToMoeny = function (moeny) {
  if (moeny == null) return '0'

  return numeral(moeny).format('')
}

export const ConvertToTree = function (info: string) {
  //return info.replaceAll('|', '=>')
  const agents = info.split('|')
  agents.pop()
  return agents.join(' ➡ ')
}

export const pad = (number, length) => {
  let str = `${number}`
  while (str.length < length) {
    str = `0${str}`
  }

  return str
}

export const GetToday = (): any => {
  const today = new Date()
  return {
    begin: new Date(today.setHours(0, 0, 0, 0)),
    end: new Date(today.setDate(today.getDate() + 1)),
  }
}

export const GetCode = (): any => {
  const today = new Date()

  const yyyy = today.getUTCFullYear().toString()
  const MM = pad(today.getUTCMonth() + 1, 2)
  const dd = pad(today.getUTCDate(), 2)
  const hh = pad(today.getUTCHours(), 2)
  const mm = pad(today.getUTCMinutes(), 2)
  const ss = pad(today.getUTCSeconds(), 2)
  const kk = pad(today.getUTCMilliseconds(), 2)
  return `${yyyy}${MM}${dd}${hh}${mm}${ss}${kk}`
}

export const ConverDateFull2 = (today): any => {
  if (today == null) {
    return '-'
  }

  const date = new Date(today)

  const yyyy = date.getFullYear().toString()
  const MM = pad(date.getMonth() + 1, 2)
  const dd = pad(date.getDate(), 2)
  const hh = pad(date.getHours(), 2)
  const mm = pad(date.getMinutes(), 2)
  const ss = pad(date.getSeconds(), 2)

  return `${yyyy}-${MM}-${dd} ${hh}:${mm}:${ss}`
}

export const ConverBalanceType = (type): any => {
  if (type === 'admin_give_balance') {
    return '어드민 지급'
  }

  return '-'
}

export async function Sleep(ms: any) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms)
  })
}
