import { Suspense, lazy } from 'react'
// import type { PartialRouteObject } from "react-router";
// import { Navigate } from 'react-router-dom';
// import AuthGuard from "./components/AuthGuard";
// import BlogLayout from './components/blog/BlogLayout';
// import BrowseLayout from './components/BrowseLayout';
// import DashboardLayout from './components/dashboard/DashboardLayout';
// import DocsLayout from './components/docs/DocsLayout';
// import GuestGuard from './components/GuestGuard';
import LoadingScreen from './components/LoadingScreen'
// import MainLayout from './components/MainLayout';
import DashboardLayout from './service/dashboard/DashboardLayout'
import AuthGuard from './service/AuthGuard'

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  )

// Browse pages

// const Browse = Loadable(lazy(() => import('./pages/browse/Browse')));
// const BrowseButtons = Loadable(lazy(() => import('./pages/browse/BrowseButtons')));
// const BrowseCharts = Loadable(lazy(() => import('./pages/browse/BrowseCharts')));
// const BrowseColors = Loadable(lazy(() => import('./pages/browse/BrowseColors')));
// const BrowseDetailLists = Loadable(lazy(() => import('./pages/browse/BrowseDetailLists')));
// const BrowseForms = Loadable(lazy(() => import('./pages/browse/BrowseForms')));
// const BrowseGridLists = Loadable(lazy(() => import('./pages/browse/BrowseGridLists')));
// const BrowseGroupedLists = Loadable(lazy(() => import('./pages/browse/BrowseGroupedLists')));
// const BrowseInputs = Loadable(lazy(() => import('./pages/browse/BrowseInputs')));
// const BrowseModals = Loadable(lazy(() => import('./pages/browse/BrowseModals')));
// const BrowseQuickStats = Loadable(lazy(() => import('./pages/browse/BrowseQuickStats')));
// const BrowseTables = Loadable(lazy(() => import('./pages/browse/BrowseTables')));
// const BrowseTypography = Loadable(lazy(() => import('./pages/browse/BrowseTypography')));

// Authentication pages

// const Login = Loadable(lazy(() => import('./pages/authentication/Login')));
// const PasswordRecovery = Loadable(lazy(() => import('./pages/authentication/PasswordRecovery')));
// const PasswordReset = Loadable(lazy(() => import('./pages/authentication/PasswordReset')));
// const Register = Loadable(lazy(() => import('./pages/authentication/Register')));
// const VerifyCode = Loadable(lazy(() => import('./pages/authentication/VerifyCode')));

// Blog pages

// const BlogPostCreate = Loadable(lazy(() => import('./pages/blog/BlogPostCreate')));
// const BlogPostDetails = Loadable(lazy(() => import('./pages/blog/BlogPostDetails')));
// const BlogPostList = Loadable(lazy(() => import('./pages/blog/BlogPostList')));

// Dashboard pages

// const Account = Loadable(lazy(() => import('./pages/dashboard/Account')));
// const Analytics = Loadable(lazy(() => import('./pages/dashboard/Analytics')));
// const Calendar = Loadable(lazy(() => import('./pages/dashboard/Calendar')));
// const Chat = Loadable(lazy(() => import('./pages/dashboard/Chat')));
// const CustomerDetails = Loadable(lazy(() => import('./pages/dashboard/CustomerDetails')));
// const CustomerEdit = Loadable(lazy(() => import('./pages/dashboard/CustomerEdit')));
// const CustomerList = Loadable(lazy(() => import('./pages/dashboard/CustomerList')));
// const Finance = Loadable(lazy(() => import('./pages/dashboard/Finance')));
// const InvoiceDetails = Loadable(lazy(() => import('./pages/dashboard/InvoiceDetails')));
// const InvoiceList = Loadable(lazy(() => import('./pages/dashboard/InvoiceList')));
// const Kanban = Loadable(lazy(() => import('./pages/dashboard/Kanban')));
// const Mail = Loadable(lazy(() => import('./pages/dashboard/Mail')));
// const OrderDetails = Loadable(lazy(() => import('./pages/dashboard/OrderDetails')));
// const OrderList = Loadable(lazy(() => import('./pages/dashboard/OrderList')));
// const Overview = Loadable(lazy(() => import('./pages/dashboard/Overview')));
// const ProductCreate = Loadable(lazy(() => import('./pages/dashboard/ProductCreate')));
// const ProductList = Loadable(lazy(() => import('./pages/dashboard/ProductList')));

// Docs pages

// const Docs = Loadable(lazy(() => import('./pages/Docs')));

// Error pages

// const AuthorizationRequired = Loadable(lazy(() => import('./pages/AuthorizationRequired')));
// const NotFound = Loadable(lazy(() => import('./pages/NotFound')));
// const ServerError = Loadable(lazy(() => import('./pages/ServerError')));

// Projects pages

// const ProjectBrowse = Loadable(lazy(() => import('./pages/dashboard/ProjectBrowse')));
// const ProjectCreate = Loadable(lazy(() => import('./pages/dashboard/ProjectCreate')));
// const ProjectDetails = Loadable(lazy(() => import('./pages/dashboard/ProjectDetails')));

// Social pages

// const SocialFeed = Loadable(lazy(() => import('./pages/dashboard/SocialFeed')));
// const SocialProfile = Loadable(lazy(() => import('./pages/dashboard/SocialProfile')));

// Other pages

// const Checkout = Loadable(lazy(() => import('./pages/Checkout')));
// const Contact = Loadable(lazy(() => import('./pages/Contact')));
// const Home = Loadable(lazy(() => import('./pages/Home')));
// const Pricing = Loadable(lazy(() => import('./pages/Pricing')));

// Service

const Login = Loadable(lazy(() => import('./service/Login')))
const Overview = Loadable(lazy(() => import('./service/dashboard/Overview')))
const Home = Loadable(lazy(() => import('./service/Home')))
const Setting = Loadable(lazy(() => import('./service/dashboard/Setting')))
const Limit = Loadable(lazy(() => import('./service/dashboard/Limit')))
const Template = Loadable(lazy(() => import('./service/dashboard/Template')))

const Hamster = Loadable(lazy(() => import('./service/dashboard/game/hamster')))

const Waterbeetle = Loadable(lazy(() => import('./service/dashboard/game/waterbeetle')))

const Waterwheel = Loadable(lazy(() => import('./service/dashboard/game/waterwheel')))

const AgentList = Loadable(lazy(() => import('./service/dashboard/agent/agentList')))
const AgentReg = Loadable(lazy(() => import('./service/dashboard/agent/agentReg')))
const AgentDetail = Loadable(lazy(() => import('./service/dashboard/agent/agentDetail')))
const TransactionList = Loadable(lazy(() => import('./service/dashboard/agent/transactionList')))
const PaymentList = Loadable(lazy(() => import('./service/dashboard/agent/paymentList')))
const ChargeList = Loadable(lazy(() => import('./service/dashboard/agent/chargeList')))
const ChargeRequest = Loadable(lazy(() => import('./service/dashboard/agent/chargeRequest')))
const InquiryList = Loadable(lazy(() => import('./service/dashboard/agent/inquiryList')))
const InquiryReg = Loadable(lazy(() => import('./service/dashboard/agent/inquiryReg')))
const Notifications = Loadable(lazy(() => import('./service/dashboard/agent/notifications')))

const UserList = Loadable(lazy(() => import('./service/dashboard/user/userList')))
const UserDetail = Loadable(lazy(() => import('./service/dashboard/user/userDetail')))
const UserTransactionList = Loadable(lazy(() => import('./service/dashboard/user/transactionList')))
const UserPaymentList = Loadable(lazy(() => import('./service/dashboard/user/paymentList')))
const BettingList = Loadable(lazy(() => import('./service/dashboard/user/bettingList')))
const BettingDetail = Loadable(lazy(() => import('./service/dashboard/user/bettingDetail')))
const NewBettingList = Loadable(lazy(() => import('./service/dashboard/user/newBettingList')))
const NewBettingDetail = Loadable(lazy(() => import('./service/dashboard/user/newBettingDetail')))

const GameSetting = Loadable(lazy(() => import('./service/dashboard/game/game-setting')))

const StatisticAgentProfit = Loadable(lazy(() => import('./service/dashboard/statistic/agentProfit')))
const StatisticUserProfit = Loadable(lazy(() => import('./service/dashboard/statistic/userProfit')))
const StatisticCasino = Loadable(lazy(() => import('./service/dashboard/statistic/casino')))
const StatisticGame = Loadable(lazy(() => import('./service/dashboard/statistic/game')))
const StatisticConnector = Loadable(lazy(() => import('./service/dashboard/statistic/connector')))

const DevTransferApi = Loadable(lazy(() => import('./service/dashboard/dev/transferApi')))
const DevCallbackApi = Loadable(lazy(() => import('./service/dashboard/dev/callbackApi')))
const DevStatusCode = Loadable(lazy(() => import('./service/dashboard/dev/statusCode')))
const DevExtendApi = Loadable(lazy(() => import('./service/dashboard/dev/extendApi')))
const DevErrorLog = Loadable(lazy(() => import('./service/dashboard/dev/errorLog')))

const routes: any[] = [
  {
    path: '*',
    children: [
      {
        path: 'home',
        element: <Home />,
      },
      {
        path: '',
        element: <Login />,
      },
      {
        path: 'dashboard',
        element: (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        ),
        children: [
          {
            path: 'overview',
            element: <Overview />,
          },
          {
            path: 'setting',
            element: <Setting />,
          },
          {
            path: 'limit',
            element: <Limit />,
          },
          {
            path: 'template',
            element: <Template />,
          },
        ],
      },
      {
        path: 'game',
        element: (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        ),
        children: [
          {
            path: 'hamster',
            element: <Hamster />,
          },
          {
            path: 'waterbeetle',
            element: <Waterbeetle />,
          },
          {
            path: 'waterwheel',
            element: <Waterwheel />,
          },
        ],
      },

      {
        path: 'agent',
        element: (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        ),
        children: [
          {
            path: 'agentList',
            element: <AgentList />,
          },
          {
            path: 'agentReg',
            element: <AgentReg />,
          },
          {
            path: 'agentDetail/:no',
            element: <AgentDetail />,
          },
          {
            path: 'transactionList',
            element: <TransactionList />,
          },
          {
            path: 'paymentList',
            element: <PaymentList />,
          },
          {
            path: 'paymentList/:type',
            element: <PaymentList />,
          },
          {
            path: 'chargeList',
            element: <ChargeList />,
          },
          {
            path: 'chargeList/:type',
            element: <ChargeList />,
          },
          {
            path: 'chargeRequest',
            element: <ChargeRequest />,
          },
          {
            path: 'inquiryList',
            element: <InquiryList />,
          },
          {
            path: 'inquiryList/:type',
            element: <InquiryList />,
          },
          {
            path: 'inquiryReg',
            element: <InquiryReg />,
          },
          {
            path: 'notifications',
            element: <Notifications />,
          },
          {
            path: 'notifications/:type',
            element: <Notifications />,
          },
        ],
      },
      {
        path: 'user',
        element: (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        ),
        children: [
          {
            path: 'userList',
            element: <UserList />,
          },
          {
            path: 'userDetail/:no',
            element: <UserDetail />,
          },
          {
            path: 'transactionList',
            element: <UserTransactionList />,
          },
          {
            path: 'paymentList',
            element: <UserPaymentList />,
          },
          {
            path: 'paymentList/:type',
            element: <UserPaymentList />,
          },
          {
            path: 'bettingList',
            element: <BettingList />,
          },
          {
            path: 'bettingList/:type',
            element: <BettingList />,
          },
          {
            path: 'bettingDetail/:no',
            element: <BettingDetail />,
          },
          {
            path: 'newBettingList',
            element: <NewBettingList />,
          },
          {
            path: 'newBettingList/:type',
            element: <NewBettingList />,
          },
          {
            path: 'newBettingDetail/:no',
            element: <NewBettingDetail />,
          },
        ],
      },
      {
        path: 'game',
        element: (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        ),
        children: [
          {
            path: 'game-setting',
            element: <GameSetting />,
          },
        ],
      },
      {
        path: 'statistic',
        element: (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        ),
        children: [
          {
            path: 'agentProfit',
            element: <StatisticAgentProfit />,
          },
          {
            path: 'userProfit',
            element: <StatisticUserProfit />,
          },
          {
            path: 'casino',
            element: <StatisticCasino />,
          },
          {
            path: 'game11',
            element: <StatisticGame />,
          },
          {
            path: 'connector',
            element: <StatisticConnector />,
          },
        ],
      },
      {
        path: 'dev',
        element: (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        ),
        children: [
          {
            path: 'basicApi',
            element: <DevTransferApi />,
          },
          {
            path: 'callbackApi',
            element: <DevCallbackApi />,
          },
          {
            path: 'statusCode',
            element: <DevStatusCode />,
          },
          {
            path: 'extendApi',
            element: <DevExtendApi />,
          },
          {
            path: 'errorLog',
            element: <DevErrorLog />,
          },
        ],
      },
    ],
  },
]

// const routes: PartialRouteObject[] = [
//   {
//     path: 'service',
//     children: [
//       {
//         path: '/',
//         element: <LoginN />
//       },
//       {
//         path: 'dashboard',
//         element: (
//           <AuthGuard>
//             <DashboardLayout />
//           </AuthGuard>
//         ),
//         children: [
//           {
//             path: '/',
//             element: <OverviewN />
//           }
//         ]
//       }
//     ]
//   },
//   {
//     path: 'authentication',
//     children: [
//       {
//         path: 'login',
//         element: (
//           <GuestGuard>
//             <Login />
//           </GuestGuard>
//         )
//       },
//       {
//         path: 'login-unguarded',
//         element: <Login />
//       },
//       {
//         path: 'password-recovery',
//         element: <PasswordRecovery />
//       },
//       {
//         path: 'password-reset',
//         element: <PasswordReset />
//       },
//       {
//         path: 'register',
//         element: (
//           <GuestGuard>
//             <Register />
//           </GuestGuard>
//         )
//       },
//       {
//         path: 'register-unguarded',
//         element: <Register />
//       },
//       {
//         path: 'verify-code',
//         element: <VerifyCode />
//       }
//     ]
//   },
//   {
//     path: 'blog',
//     element: <BlogLayout />,
//     children: [
//       {
//         path: '/',
//         element: <BlogPostList />
//       },
//       {
//         path: 'new',
//         element: <BlogPostCreate />
//       },
//       {
//         path: ':postId',
//         element: <BlogPostDetails />
//       }
//     ]
//   },
//   {
//     path: 'contact',
//     element: <Contact />
//   },
//   {
//     path: 'dashboard',
//     element: (
//       <AuthGuard>
//         <DashboardLayout />
//       </AuthGuard>
//     ),
//     children: [
//       {
//         path: '/',
//         element: <Overview />
//       },
//       {
//         path: 'account',
//         element: <Account />
//       },
//       {
//         path: 'analytics',
//         element: <Analytics />
//       },
//       {
//         path: 'calendar',
//         element: <Calendar />
//       },
//       {
//         path: 'chat',
//         children: [
//           {
//             path: '/',
//             element: <Chat />
//           },
//           {
//             path: 'new',
//             element: <Chat />
//           },
//           {
//             path: ':threadKey',
//             element: <Chat />
//           }
//         ]
//       },
//       {
//         path: 'customers',
//         children: [
//           {
//             path: '/',
//             element: <CustomerList />
//           },
//           {
//             path: ':customerId',
//             element: <CustomerDetails />
//           },
//           {
//             path: ':customerId/edit',
//             element: <CustomerEdit />
//           }
//         ]
//       },
//       {
//         path: 'invoices',
//         children: [
//           {
//             path: '/',
//             element: <InvoiceList />
//           },
//           {
//             path: ':invoiceId',
//             element: <InvoiceDetails />
//           }
//         ]
//       },
//       {
//         path: 'kanban',
//         element: <Kanban />
//       },
//       {
//         path: 'mail',
//         children: [
//           {
//             path: '/',
//             element: (
//               <Navigate
//                 to="/dashboard/mail/all"
//                 replace
//               />
//             )
//           },
//           {
//             path: 'label/:customLabel',
//             element: <Mail />
//           },
//           {
//             path: 'label/:customLabel/:emailId',
//             element: <Mail />
//           },
//           {
//             path: ':systemLabel',
//             element: <Mail />
//           },
//           {
//             path: ':systemLabel/:emailId',
//             element: <Mail />
//           }
//         ]
//       },
//       {
//         path: 'orders',
//         children: [
//           {
//             path: '/',
//             element: <OrderList />
//           },
//           {
//             path: ':orderId',
//             element: <OrderDetails />
//           }
//         ]
//       },
//       {
//         path: 'finance',
//         element: <Finance />
//       },
//       {
//         path: 'products',
//         children: [
//           {
//             path: '/',
//             element: <ProductList />
//           },
//           {
//             path: 'new',
//             element: <ProductCreate />
//           }
//         ]
//       },
//       {
//         path: 'projects',
//         children: [
//           {
//             path: 'browse',
//             element: <ProjectBrowse />
//           },
//           {
//             path: 'new',
//             element: <ProjectCreate />
//           },
//           {
//             path: ':projectId',
//             element: <ProjectDetails />
//           }
//         ]
//       },
//       {
//         path: 'social',
//         children: [
//           {
//             path: 'feed',
//             element: <SocialFeed />
//           },
//           {
//             path: 'profile',
//             element: <SocialProfile />
//           }
//         ]
//       }
//     ]
//   },
//   {
//     path: 'docs',
//     element: <DocsLayout />,
//     children: [
//       {
//         path: '/',
//         element: (
//           <Navigate
//             to="/docs/overview/welcome"
//             replace
//           />
//         )
//       },
//       {
//         path: '*',
//         element: <Docs />
//       }
//     ]
//   },
//   {
//     path: '*',
//     element: <MainLayout />,
//     children: [
//       {
//         path: '/',
//         element: <Home />
//       },
//       {
//         path: 'browse',
//         element: <BrowseLayout />,
//         children: [
//           {
//             path: '/',
//             element: <Browse />
//           },
//           {
//             path: '/buttons',
//             element: <BrowseButtons />
//           },
//           {
//             path: '/inputs',
//             element: <BrowseInputs />
//           },
//           {
//             path: '/charts',
//             element: <BrowseCharts />
//           },
//           {
//             path: '/colors',
//             element: <BrowseColors />
//           },
//           {
//             path: '/data-display/detail-lists',
//             element: <BrowseDetailLists />
//           },
//           {
//             path: '/data-display/quick-stats',
//             element: <BrowseQuickStats />
//           },
//           {
//             path: '/data-display/tables',
//             element: <BrowseTables />
//           },
//           {
//             path: '/forms',
//             element: <BrowseForms />
//           },
//           {
//             path: '/modals',
//             element: <BrowseModals />
//           },
//           {
//             path: '/lists/grouped-lists',
//             element: <BrowseGroupedLists />
//           },
//           {
//             path: '/lists/grid-lists',
//             element: <BrowseGridLists />
//           },
//           {
//             path: '/typography',
//             element: <BrowseTypography />
//           }
//         ]
//       },
//       {
//         path: 'checkout',
//         element: <Checkout />
//       },
//       {
//         path: 'pricing',
//         element: <Pricing />
//       },
//       {
//         path: '401',
//         element: <AuthorizationRequired />
//       },
//       {
//         path: '404',
//         element: <NotFound />
//       },
//       {
//         path: '500',
//         element: <ServerError />
//       },
//       {
//         path: '*',
//         element: <NotFound />
//       }
//     ]
//   }
// ];

export default routes
