import cookie from 'react-cookies'
import { BetData } from './interface'

console.log(`web : ${process.env.REACT_APP_API_URL}`)

const URL = process.env.REACT_APP_API_URL

interface Agent {
  idx: number

  agentId: string

  agentCode: string

  agentTree: string

  password: string

  nick: string

  level: number

  balance: number

  totalCharge: number

  totalSubBalance: number

  totalGive: number

  percent: number

  apiToken: string

  walletMode: string

  seamlessUrl: string

  status: string

  session: string

  regDate: Date

  lastDate: Date

  removeDate: Date

  message: string
}

export class RPC {
  static SetSession = () => {
    // axios.defaults.headers.post["Authorization"] = _session;
  }

  async SendMessageByGet(url: any) {
    const response = await fetch(`${URL}${url}`, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      headers: {
        'Content-Type': 'application/json',
        Authorization: cookie.load('authorization'),
      },
    })

    if (response.ok) {
      const json = await response.json()

      return json
      // HTTP 상태 코드가 200~299일 경우
    } else {
      alert('서버와의 통신이 원활하지 않습니다.')
    }
    return {}
  }

  async SendMessageByPost(url: any, params: any) {
    const session = cookie.load('authorization')

    // axios.defaults.headers.post["authorization"] = session;

    // const response = await axios.post(`${URL}${url}`, JSON.stringify(params), {
    //   headers: { 'Content-Type': 'application/json', Authorization: session },
    // })

    const response = await fetch(`${URL}${url}`, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        Authorization: session,
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(params), // body data type must match "Content-Type" header
    })
    if (response.ok) {
      const json = await response.json()

      return json
      // HTTP 상태 코드가 200~299일 경우
    } else {
      alert('서버와의 통신이 원활하지 않습니다.')
    }
    return {}
  }

  async doLogin(id: string, pw: string) {
    return this.SendMessageByPost('agent/doLogin', { agentId: id, password: pw })
  }

  async healthCheck() {
    return this.SendMessageByPost('agent/user_health_check', {})
  }

  async getInfo() {
    return this.SendMessageByPost('agent/getInfo', {})
  }

  async editBetLimit(gameType, min, max) {
    return this.SendMessageByPost('agent/editBetLimit', { gameType, min, max })
  }

  async changePassword(oldpass, newpass) {
    return this.SendMessageByPost('agent/changePassword', { oldpass, newpass })
  }

  async changeKey() {
    return this.SendMessageByPost('agent/changeKey', {})
  }

  async changeNick(query) {
    return this.SendMessageByPost('agent/changeNickname', query)
  }

  async masterReg(query) {
    const data = await this.SendMessageByPost('agent/makeAgent', query)

    return data
  }

  async getMasterTree(query) {
    return this.SendMessageByPost('agent/getMasterTree', query)
  }

  async getAgents(query) {
    return this.SendMessageByPost('agent/getAgents', query)
  }

  giveBalance = async function (query) {
    return this.SendMessageByPost('agent/giveBalance', query)
  }

  applyChange = async function (query) {
    return this.SendMessageByPost('agent/applyChange', query)
  }

  getAgentChange = async function (query) {
    return this.SendMessageByPost('agent/getAgentChange', query)
  }

  getTransaction = async function (query): Promise<any> {
    return this.SendMessageByPost('agent/getTransaction', query)
  }

  getUser = async function (query): Promise<any> {
    return this.SendMessageByPost('agent/getUsers', query)
  }

  userLimit = async function (query): Promise<any> {
    return this.SendMessageByPost('agent/userLimit', query)
  }

  getUserLimit = async function (query): Promise<any> {
    return this.SendMessageByPost('agent/getUserLimit', query)
  }

  deleteUserLimit = async function (query): Promise<any> {
    return this.SendMessageByPost('agent/deleteUserLimit', query)
  }


  giveUserBalance = async function (query): Promise<any> {
    return this.SendMessageByPost('agent/giveUserBalance', query)
  }

  async getVendors(): Promise<any> {
    return this.SendMessageByGet('agent/vendors')
  }

  async getAgentGameSetting(): Promise<any> {
    return this.SendMessageByGet('agent/game-setting')
  }
  async getGames(vendor: string): Promise<any> {
    return this.SendMessageByGet(`agent/games?vendor=${vendor}`)
  }

  async setAgentGameAllowBet(query: { vendor: string; gameId: string; allowBet: boolean }): Promise<any> {
    return this.SendMessageByPost('agent/game-allow-bet', query)
  }

  getBetCasinos = async function (query): Promise<{
    status: string
    bets: BetData[]
    data: {
      totalBet: number
      totalWin: number
      count: number
    }
  }> {
    return this.SendMessageByPost('agent/getBetCasinos', query)
  }

  getBetSlot = async function (query): Promise<{
    status: string
    bets: BetData[]
    data: {
      totalBet: number
      totalWin: number
      count: number
    }
  }> {
    return this.SendMessageByPost('agent/getBetSlot', query)
  }

  getBetSport = async function (query): Promise<{
    status: string
    bets: BetData[]
    data: {
      totalBet: number
      totalWin: number
      count: number
    }
  }> {
    return this.SendMessageByPost('agent/getBetSport', query)
  }

  getBalanceLog = async function (query): Promise<any> {
    return this.SendMessageByPost('agent/getBalanceLog', query)
  }

  getCalculator = async function (query): Promise<any> {
    return this.SendMessageByPost('agent/getCalculator', query)
  }

  getUserCalculator = async function (query): Promise<any> {
    return this.SendMessageByPost('agent/getUserCalculator', query)
  }

  getSimpleAgent = async function (query): Promise<{
    status: string
    simpleAgent: Agent[]
  }> {
    return this.SendMessageByPost('agent/getSimpleAgent', query)
  }

  getAgentDetail = async function (query): Promise<any> {
    return this.SendMessageByPost('agent/getAgentDetail', query)
  }

  saveDetail = async function (query): Promise<any> {
    return this.SendMessageByPost('agent/editAgent', query)
  }

  getBalanceGiveLog = async function (query): Promise<any> {
    return this.SendMessageByPost('agent/getBalanceGiveLog', query)
  }

  getTableInfo = async function () {
    return this.SendMessageByPost('agent/getTableInfo', {})
  }

  updateLimit = async function (id, min, max, win) {
    return this.SendMessageByPost('agent/updateLimit', { gameid: id, minBet: min, maxBet: max, maxWin: win })
  }

  removeAgent = async function (agentCode) {
    return this.SendMessageByPost('agent/removeAgent', { agentCode })
  }

  //     const data = await this.SendMessageByPost("/agent/getMasterTree", query);

  //     return data
  // };
}
