import { useEffect } from 'react'
import type { FC } from 'react'
import { useRoutes } from 'react-router-dom'
import { Toaster } from 'react-hot-toast'
import { CssBaseline, ThemeProvider } from '@mui/material'
import './i18n'
// import SettingsDrawer from "./components/SettingsDrawer";
import SplashScreen from './components/SplashScreen'
import { gtmConfig } from './config'
import useAuth from './hooks/useAuth'
import useScrollReset from './hooks/useScrollReset'
import useSettings from './hooks/useSettings'
import gtm from './lib/gtm'
import routes from './routes'
import { createCustomTheme } from './theme'
// import { hot } from 'react-hot-loader';

import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import './App.css'

const App: FC = () => {
  const content = useRoutes(routes)
  const { settings } = useSettings()
  const auth = useAuth()

  useScrollReset()

  useEffect(() => {
    gtm.initialize(gtmConfig)
  }, [])

  const theme = createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme,
  })

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Toaster position="top-center" />
      {/* <SettingsDrawer /> */}
      {auth.isInitialized ? content : <SplashScreen />}
    </ThemeProvider>
  )
}

// export default App;
export default App
