import createResourceId from '../utils/createResourceId'
import { sign, JWT_SECRET, JWT_EXPIRES_IN } from '../utils/jwt'
import wait from '../utils/wait'
import { User } from '../types/user'

import cookie from 'react-cookies'

import { RPC } from '../connection/rpc'

const users = [
  {
    id: '5e86809283e28b96d2d38537',
    avatar: '/static/mock-images/avatars/avatar-jane_rotanson.png',
    email: 'demo@devias.io',
    name: 'Jane Rotanson',
    password: 'Password123!',
    plan: 'Premium',
  },
]

class AuthApi {
  rpc = new RPC()

  async login({ email, password }): Promise<string> {
    await wait(500)

    const response: any = await this.rpc.doLogin(email, password)

    if (response.status !== 'success') {
      return new Promise((resolve, reject) => reject(new Error('Please check your email and password')))
    }

    cookie.save('authorization', response.session, { path: '/' })

    return response
    //    return response
    // return new Promise((resolve, reject) => {
    //   try {
    //     // Find the user
    //     const user = users.find((_user) => _user.email === email);

    //     if (!user || (user.password !== password)) {
    //       reject(new Error('Please check your email and password'));
    //       return;
    //     }

    //     // Create the access token
    //     const accessToken = sign(
    //       { userId: user.id },
    //       JWT_SECRET,
    //       { expiresIn: JWT_EXPIRES_IN }
    //     );

    //     resolve(accessToken);
    //   } catch (err) {
    //     console.error('[Auth Api]: ', err);
    //     reject(new Error('Internal server error'));
    //   }
    // });
  }

  async register({ email, name, password }): Promise<string> {
    await wait(1000)

    return new Promise((resolve, reject) => {
      try {
        // Check if a user already exists
        let user = users.find((_user) => _user.email === email)

        if (user) {
          reject(new Error('User already exists'))
          return
        }

        user = {
          id: createResourceId(),
          avatar: null,
          email,
          name,
          password,
          plan: 'Standard',
        }

        users.push(user)

        const accessToken = sign({ userId: user.id }, JWT_SECRET, { expiresIn: JWT_EXPIRES_IN })

        resolve(accessToken)
      } catch (err) {
        console.error('[Auth Api]: ', err)
        reject(new Error('Internal server error'))
      }
    })
  }

  me(info): Promise<User> {
    return new Promise((resolve, reject) => {
      try {
        // Decode access token
        // const { userId } = decode(accessToken) as any;

        // // Find the user
        // const user = users.find((_user) => _user.id === userId);

        // if (!user) {
        //   reject(new Error('Invalid authorization token'));
        //   return;
        // }

        resolve({
          session: info.session,
          agentId: info.agentId,
          balance: info.balance,
          nick: info.nick,

          // avatar: user.avatar,
          // email: user.email,
          // name: user.name,
          // plan: user.plan
        })
      } catch (err) {
        console.error('[Auth Api]: ', err)
        reject(new Error('Internal server error'))
      }
    })
  }
}

export const authApi = new AuthApi()
